import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";
import { invertors } from "./data";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";

const index = () => {
  const settings = {
    prevArrow: "",
    dots: false,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
          centerMode: false,
          arrows: true,
        },
      },
    ],
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="wwu-investors">
      <div className="wwu-title-investors">  Our Investors </div>
      <StaticImage
        className="wwu-invest-undeline"
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
      />
      <div className="wwu-desc" ref={ref}>
        Kraftshala is backed by a number of eminent investors including
        ex-unicorn startup founders and CXOs of global giants. Chances are,
        you’ve already used products they’ve created / taken to new heights.
        Find some of them here, among other business leaders
        {inView && (
          <>
            <svg
              width="141"
              height="4"
              viewBox="0 0 141 4"
              fill="none"
              className="wwu-invest-svg-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5C6.25333 1.5 11.489 1 16.75 1C27.0257 1 37.2471 1.77478 47.5139 1.97222C62.9893 2.26983 78.4479 1.72014 93.9167 1.51389C105.267 1.36256 116.649 1.39586 128 1.5C132.007 1.53676 135.982 2 140 2"
                stroke="#505050"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <svg
              width="275"
              height="8"
              viewBox="0 0 275 8"
              fill="none"
              className="wwu-invest-svg-2 mobile-none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 5.00016C26.8091 5.00016 52.5427 4.98655 78.3333 4.22238C94.3499 3.74781 109.275 4.83984 125.111 6.38904C135.187 7.37468 143.808 5.96365 153.667 5.22238C166.549 4.25381 179.39 4.38001 192.278 3.88904C206.702 3.33953 221.003 0.906441 235.444 1.77793C248.156 2.54504 261.32 4.00016 274 4.00016"
                stroke="#505050"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              className="wwu-invest-svg-3"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.49902 1.99991C11.444 0.0614858 19.1807 1.05929 23.8879 10.9444C27.2964 18.1021 26.999 26.2597 26.999 33.9999"
                stroke="#505050"
                stroke-width="1.5"
                stroke-linecap="round"
                className="arrow-line"
              />
              <path
                d="M19.999 28C22.0823 29.4274 24.5142 30.8595 26.0824 32.8889C26.138 32.9609 26.5937 34.2125 26.8879 33.8889C27.709 32.9857 27.9952 31.5093 28.8324 30.5278C30.1305 29.0059 31.9529 27.765 33.499 26.5"
                stroke="#505050"
                stroke-width="1.5"
                stroke-linecap="round"
                className="arrow-inventory"
              />
            </svg>
            <svg
              className="pc-none wwu-invest-svg-4"
              width="210"
              height="5"
              viewBox="0 0 210 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="undeline-svg"
                d="M1 1.66019C9.29202 0.798696 18.2876 1.14233 26.617 0.937966C37.9933 0.658852 49.3893 1.36087 60.7607 1.66019C81.5638 2.20776 102.38 1.93797 123.186 1.93797C142.335 1.93797 161.51 0.970629 180.66 0.549077C189.474 0.355049 200.431 3.52783 209.25 3.52783"
                stroke="#505050"
                stroke-linecap="round"
              />
            </svg>
            <svg
              width="144"
              className="pc-none wwu-invest-svg-5"
              height="3"
              viewBox="0 0 144 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.27783C6.47167 1.96179 12.2049 1.27783 17.7222 1.27783C23.0926 1.27783 28.463 1.27783 33.8333 1.27783C46.3776 1.27783 58.865 2.19544 71.3889 2.27783C84.9935 2.36734 98.6183 2.42815 112.222 2.27783C122.664 2.16245 133.029 0.777832 143.5 0.777832"
                stroke="#505050"
                stroke-linecap="round"
              />
            </svg>
          </>
        )}
      </div>

      <div className="invertors-papers mobile-none">
        {invertors.map((invest) => (
          <div className="investor-paper">
            {inView && (
              <img src={invest.pin} className="wwu-pin" loading="lazy" />
            )}
            <div
              className="i-p-head"
              dangerouslySetInnerHTML={{ __html: invest.title }}
            />
            <ul
              className="i-p-desc"
              dangerouslySetInnerHTML={{ __html: invest.bullet }}
            />
          </div>
        ))}
      </div>

      <div className="mlp-main-placements mlp-main wwu-invest-slick">
        <div className="placements-container basl-placement-container">
          <div className="placement-slider basl-alumni-slider invertors-paper-mob">
            <Slider {...settings}>
              {invertors.map((invest) => (
                <div className="investor-paper">
                  {inView && (
                    <img src={invest.pin} className="wwu-pin" loading="lazy" />
                  )}
                  {/* {inView && <img src={invest.pin} className="wwu-pin" loading="lazy" /> } */}
                  <div
                    className="i-p-head"
                    dangerouslySetInnerHTML={{ __html: invest.title }}
                  />
                  <div
                    className="i-p-desc"
                    dangerouslySetInnerHTML={{ __html: invest.bullet }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
