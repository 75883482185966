import React, { useState } from "react";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";
import { values } from "./data";

const index = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div className="wwu-KSKode">
      <div className="wwu-title-kode">
          What We Value - The Kraftshala Kode
      </div>
      <img
        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46ncmpr.png"
        className="kodeleftDot"
        loading="lazy"
      />
      <img
        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46nefj9.png"
        className="koderightDot"
        loading="lazy"
      />

      <StaticImage
        className="wwu-kode-undeline"
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
      />
      <div className="wwu-desc">
        Kraftshala is on a mission to become the largest career launchpad in the
        world.
        <br />
        <br />
        Team Kraftshala is for the ambitious, the forever-learners, and most of
        all, for those who live to add value to others. In a world where
        education companies often lose sight of their core, we aim to{" "}
        <b>Scale with Soul,</b> to offer greater value to each stakeholder
        (including our team) everyday we get bigger.
        <br />
        <br />
        Joining Kraftshala now means becoming part of a story still in its early
        chapters- one you’ll help shape with your ideas, actions, and
        leadership. Here are things we care for:
      </div>
      <div className="wwu-kode-cards">
        <div className="kode-main-container">
          {selected === 0 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "332px" }}
            >
              We seek a{" "}
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                className="kode-circle0"
              />
              <span className="high">value-adding mindset</span> in prospective
              team mates. Folks who demonstrate a capacity to understand their
              users' needs and are willing to go the extra mile to deliver on
              that, fit right in.
            </div>
          )}
          {selected === 1 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "299px" }}
            >
              We value{" "}
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                className="kode-circle1"
              />{" "}
              <span className="high"> problem solving</span> skills. We look at
              problems objectively, work backwards from the user, solve for root
              causes and optimise for their long-term good instead of our
              short-term gain. We don’t let processes or hierarchies get in the
              way of adding value.
            </div>
          )}
          {selected === 2 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "316px" }}
            >
              We believe{" "}
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                className="kode-circle2"
              />
              <span className="high">speed</span>matters enormously in business.
              We value people who move with urgency, have a bias for action and
              are willing to take calculated risks in the face of uncertainty.
            </div>
          )}
          {selected === 3 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "302px" }}
            >
              We look for people with high{" "}
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                className="kode-circle3"
              />
              <span className="high"> learning agility, </span>those truth
              seekers who pursue the best data, ideas, and solutions with rigour
              and open mindedness, and are constantly working to become better
              today than yesterday.
            </div>
          )}
          {selected === 4 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "293px" }}
            >
              We admire people with
              <br />{" "}
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                className="kode-circle4"
              />
              <span className="high">ambition & courage</span> to take on large
              goals. We are seeking to build a team of self-starters who
              demonstrate high ownership.
            </div>
          )}
          {selected === 5 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "389px" }}
            >
              We aim for{" "}
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                className="kode-circle5"
              />
              <span className="high"> consistency and reliability</span> and
              expect the same from others. Predictability just makes everyone’s
              life a lot smoother.
            </div>
          )}
          {selected === 6 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "298px" }}
            >
              We believe people who demonstrate
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                className="kode-circle6"
              />
              <span className="high"> candour with care</span>are the most
              effective: real, open and direct in communication, while still
              being highly empathetic.
            </div>
          )}
          {/* {selected === 7 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "298px" }}
            >
              We have learnt that Scaling with Soul requires{" "}
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                className="kode-circle4"
              />
              <span className="high"> frugality.</span>Resource constraints help
              sharpen focus and breed inventiveness.
            </div>
          )} */}
          {selected === 7 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "298px" }}
            >
              We are{" "}
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                className="kode-circle4"
              />
              <span className="high"> practical optimists.</span> Our team
              strongly believes in a better future and takes joy and pride in
              the fact that we are playing our part to make that future a
              reality.
            </div>
          )}
        </div>
        <div className="kode-side-cards">
          {values.map((value, index) => {
            // Added index in the map callback
            return (
              <div
                className="wwu-kode-card"
                key={index} // Added key prop
                onClick={() => setSelected(index)}
                style={{ background: `url(${value.bgImg})` }}
              >
                <div
                  className={`wwu-kode-card-txt ${
                    index !== selected ? "default-color" : "selected-color"
                  }`}
                  //style={{ color: index !== selected ? "#505050" : "#f1ae13" }}
                  dangerouslySetInnerHTML={{ __html: value.title }}
                />
                {index !== selected ? (
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46ms0al.png"
                    className="plusImg"
                  />
                ) : (
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mqoft.png"
                    className="plusImg"
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="wwu-kode-cards-mob">
        <div className="kode-side-cards">
          {values.map((value, index) => {
            // Added index in the map callback
            return (
              <div className="kode-cover">
                <div
                  className="wwu-kode-card"
                  key={index} // Added key prop
                  onClick={() => setSelected(index)}
                  style={{ background: `url(${value.bgImg})` }}
                >
                  <div
                    className={`wwu-kode-card-txt ${
                      index !== selected ? "default-color" : "selected-color"
                    }`}
                    //style={{ color: index !== selected ? "#505050" : "#f1ae13" }}
                    dangerouslySetInnerHTML={{ __html: value.title }}
                  />
                  {index !== selected ? (
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46ms0al.png"
                      className="plusImg"
                    />
                  ) : (
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mqoft.png"
                      className="plusImg"
                    />
                  )}
                </div>
                {index === selected && selected === 0 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "292px" }}
                    >
                      We seek a
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                        className="kode-circle0"
                      />
                      <span className="high">value-adding mindset</span> in
                      prospective team mates. Folks who demonstrate a capacity
                      to understand their users' needs and are willing to go the
                      extra mile to deliver on that, fit right in.
                    </div>
                  </div>
                )}
                {index === selected && selected === 1 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "299px" }}
                    >
                      We value
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                        className="kode-circle1"
                      />{" "}
                      <span className="high"> problem solving</span> skills. We
                      look at problems objectively, work backwards from the
                      user, solve for root causes and optimise for their
                      long-term good instead of our short-term gain. We don’t
                      let processes or hierarchies get in the way of adding
                      value.
                    </div>
                  </div>
                )}
                {index === selected && selected === 2 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "316px" }}
                    >
                      We believe{" "}
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                        className="kode-circle2"
                      />
                      <span className="high">speed </span> matters enormously in
                      business. We value people who move with urgency, have a
                      bias for action and are willing to take calculated risks
                      in the face of uncertainty.
                    </div>
                  </div>
                )}
                {index === selected && selected === 3 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "302px" }}
                    >
                      We look for people with high{" "}
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                        className="kode-circle3"
                      />
                      <span className="high"> learning agility, </span>those
                      truth seekers who pursue the best data, ideas, and
                      solutions with rigour and open mindedness, and are
                      constantly working to become better today than yesterday.
                    </div>
                  </div>
                )}
                {index === selected && selected === 4 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "293px" }}
                    >
                      We admire people with
                      <br />{" "}
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                        className="kode-circle4"
                      />
                      <span className="high">ambition & courage </span>to take
                      on large goals. We are seeking to build a team of
                      self-starters who demonstrate high ownership. .
                    </div>
                  </div>
                )}
                {index === selected && selected === 5 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "369px" }}
                    >
                      We aim for <br />
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                        className="kode-circle5"
                      />
                      <span className="high"> consistency & reliability</span>{" "}
                      and expect the same from others. Predictability just makes
                      everyone’s life a lot smoother.
                    </div>
                  </div>
                )}
                {index === selected && selected === 6 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "298px" }}
                    >
                      We believe people who demonstrate <br />
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                        className="kode-circle6"
                      />
                      <span className="high"> candour with care</span> are the
                      most effective: real, open and direct in communication,
                      while still being highly empathetic.
                    </div>
                  </div>
                )}
                {/* {index === selected && selected === 7 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "298px" }}
                    >
                      We have learnt that Scaling with Soul requires{" "}
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                        className="kode-circle7"
                      />
                      <span className="high"> frugality.</span> Resource
                      constraints help sharpen focus and breed inventiveness.
                    </div>
                  </div>
                )} */}
                {index === selected && selected === 7 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "298px" }}
                    >
                      We are{" "}
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png"
                        className="kode-circle8"
                      />
                      <span className="high">practical optimists.</span> Our
                      team strongly believes in a better future and takes joy
                      and pride in the fact that we are playing our part to make
                      that future a reality.
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default index;
