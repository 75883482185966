
export const invertors = [
  {
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46rxwd1.webp",
    title: `Phanindra Sama`,
    bullet: `<li>Founder / Ex- CEO at<span class="wwu-invest-card-ylo"> Redbus</span>. Advisor at<span class="wwu-invest-card-ylo"> WestBridge Capital</span>. Former Chief Innovation Officer,<span class="wwu-invest-card-ylo"> Govt of Telangana</span></li>`,
  },
  {
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm42j3suz.png",
    title: `Amit Kumar<br/> Agarwal, Saurabh <br/>Garg & Akhil Gupta`,
    bullet: `<li>Co-founders at <span class="wwu-invest-card-ylo"> Nobroker.com </span>(the world’s largest NoBrokerage Property Site and India’s first realty startup unicorn)</li>`,
  },
  {
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46rxwd1.webp",
    title: `Pallav Jain and<br/> Sarfaraz Khimani`,
    bullet: `<li>Co-CEOs of<span class="wwu-invest-card-ylo"> Performics</span>, India's largest SEO/ performance marketing agency</li>`,
  },
  {
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46rxwd1.webp",
    title: `Sujayath Ali`,
    bullet: `<li>Co-founder at <br/>    <span class="wwu-invest-card-ylo">ShopUp </span> <br/>and <span class="wwu-invest-card-ylo">Voonik</span></li>`,
  },
  {
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46rxwd1.webp",
    title: `Doreswamy <br/>Nandkishore`,
    bullet: `<li>Former <span class="wwu-invest-card-ylo">Fortune 100 CEO</span>, Head of Asia, Oceania, Africa for <span class="wwu-invest-card-ylo">Nestlé S.A</span>., Professor at<span class="wwu-invest-card-ylo"> ISB</span></li>`,
  },
];
