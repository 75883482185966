import React from "react";
import Slider from "react-slick";

const eduData = [
  {
    title: "Injecting",
    subTitle: "CARE",
    desc: `Education is only impactful <br class="mobile-none"/>if you have someone<br class="mobile-none"/><b> looking out for you</b>,
          asking you the right questions, figuring out challenges with you, and
          <b>rooting for you</b><br class="mobile-none"/> every step of the way. We <br class="mobile-none"/>do all of that and more. `,
  },
  {
    title: "Injecting",
    subTitle: "EXCELLENCE",
    desc: `We build everything in-<br class="mobile-none"/>house and ensure our modules are most relevant to current industry practices by completely being<b> based on JDs</b>. This ensures that all the <b>fluff goes out the window</b>.`,
  },
  {
    title: "Injecting",
    subTitle: "PRACTICALITY",
    desc: `70% of our curriculum is about <b>learning by doing</b>, and the rest is about getting coached and mentored by<b> real-time industry experts</b>.`,
  },
  {
    title: "Injecting",
    subTitle: "TRANSPARENCY",
    desc: `We have analytics in place to allow us to<b> actively track and intervene</b> towards student progress to ensure <b>EVERYONE</b>’s learning. Check out our batch-wise placement reports linked below!`,
  },
];
const CardsSection = () => {
  const settings = {
    prevArrow: "",
    dots: false,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="wwu-edu-cards">
      <div className="mlp-main-placements mlp-main wwu-edu">
        <div className="placements-container basl-placement-container">
          <div className="placement-slider basl-alumni-slider">
            <Slider {...settings}>
              {eduData.map((data, index) => (
                <div className={`edu-card wwu-edu-card-${index}`} key={index}>
                  <div className="edu-card-title">{data.title}</div>
                  <div className="edu-card-subTitle">{data.subTitle}</div>
                  <div
                    className="edu-card-desc"
                    dangerouslySetInnerHTML={{ __html: data.desc }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsSection;
