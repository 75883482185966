import React from "react";
import "./styles.scss";

function HeroSection() {
  const handleClick = () => {
    const element = document.getElementById("jobOpenings");

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <div className="wwu-header-container">
      <h1 className="wwu-welcome pc-hide">
          Welcome
        <br />
          to Kraftshala
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46ql6cs.webp" className="circle-text" loading="lazy" />
      </h1>
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y8422m4gob9r4.webp" className="wwu-hero-img" loading="lazy" />
      <div className="hero-section">
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46ql6cs.webp" className="circle-text mobile-hide" loading="lazy" />
        <div className="wwu-welcome mobile-hide">
          Welcome
          <br />
          to Kraftshala
        </div>
       
        <div className="wwu-hero-desc">
          We’re an online skilling institution committed to playing the long
          game of building a transformative educational experience for the
          masses.
        </div>
        <button className="wwu-btn-hero" onClick={handleClick}>See open positions</button>
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46n66gy.png" loading="lazy" className="HerodotsLeft" />
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46n7p6c.png" loading="lazy" className="HerodotsRight" />
    </div>
  );
}

export default HeroSection;