export const values = [
  {
    title: `Value-adding <br class="mobile-hide"/>mindset `,
    bgImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mea34.webp",
  },
  {
    title: `Problem <br class="mobile-hide"/>solving`,
    bgImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mea34.webp",
  },
  {
    title: `Speed`,
    bgImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mi5wn.webp",
  },
  {
    title: `Learning <br class="mobile-hide"/>Agility`,
    bgImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mjde7.webp",
  },
  {
    title: `Ambition<br class="mobile-hide"/> & courage`,
    bgImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mkog9.webp",
  },
  {
    title: `Consistency<br class="mobile-hide"/> & reliability`,
    bgImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mm11e.webp",
  },
  {
    title: `Candour <br class="mobile-hide"/> with care`,
    bgImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mnb8b.webp",
  },
  // {
  //   title: `Frugality`,
  //   bgImg:
  //     "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mpad4.webp",
  // },
  {
    title: `Practical<br class="mobile-hide"/> Optimism`,
    bgImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mpad4.webp",
  },
];
