import React from "react";
import { students } from "./data";
import Slider from "react-slick";
import { useInView } from "react-intersection-observer";

const cardsSec = () => {
  const settings = {
    prevArrow: "",
    dots: false,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
          arrows: true,
        },
      },
    ],
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="wwu-impact-cards-sec">
      <div className="wwu-impact-cards-head" ref={ref}>
        Our focus is to get
        <span className="wwu-impact-card-span-nanum"> ALL</span> of our students
        placed in <span className="wwu-impact-card-span">great</span> companies!
        Check out some of our success stories here
        {inView && <><svg
          className="wwu-impact-undeline-svg"
          width="74"
          height="6"
          viewBox="0 0 74 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="impact-svg-line"
            d="M1 2.41954C21.1363 1.23505 41.4511 -0.0194503 61.5556 2.30843C65.1757 2.72761 69.0187 3.25912 72.5 4.41954"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
        <svg
          className="wwu-impact-arrow"
          width="34"
          height="35"
          viewBox="0 0 34 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="arrow-line"
            d="M1 2.14835C10.945 0.209923 18.6817 1.20773 23.3889 11.0928C26.7974 18.2506 26.5 26.4081 26.5 34.1483"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            className="arrow-inventory"
            d="M19.5 28.1484C21.5833 29.5759 24.0152 31.0079 25.5833 33.0373C25.639 33.1094 26.0947 34.361 26.3889 34.0373C27.21 33.1341 27.4962 31.6577 28.3333 30.6762C29.6314 29.1543 31.4538 27.9135 33 26.6484"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg></>}
      </div>
      <div className="wwu-impact-cards-container">
        <div className="mlp-main-placements mlp-main wwu-impact-slick">
          <div className="placements-container basl-placement-container">
            <div className="placement-slider basl-alumni-slider">
              <Slider {...settings}>
                {students &&
                  students.map((s) => {
                    return (
                      <>
                        <div className="wwu-impact-sec-card">
                          <img
                            src={s.bannerImg}
                            alt={s.name}
                            className="imapct-card-b-img"
                            loading="lazy"
                          />
                          <div className="course-container">
                            <div
                              className="wwu-i-course"
                              dangerouslySetInnerHTML={{ __html: s.course }}
                            />
                            <div className="wwu-i-course-sub">
                              {s.courseSub}
                            </div>
                          </div>
                          <div className="wwu-i-cards-body">
                            <div className="wwu-i-name">{s.name}</div>
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
                              loading="lazy"
                              className="wwu-i-undeline"
                              style={{ width: `${s.undelineWidth}` }}
                            />
                            <div className="wwu-i-prev">Previously:</div>
                            <div
                              className="wwu-i-previously"
                              dangerouslySetInnerHTML={{ __html: s.previous }}
                            />
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uyzx4.png"
                              loading="lazy"
                              className="yellow-arrow-dwn"
                            />
                            <div className="wwu-i-aft">After Kraftshala:</div>
                            <div
                              className="wwu-i-after"
                              dangerouslySetInnerHTML={{ __html: s.now }}
                            />
                          </div>
                          <div className="listen-to-story">
                            {s.listen}: 
                            <a
                              href={s.youtubeLink}
                              target="_blank"
                              className="yt-icon"
                            >
                              {" "}
                            </a>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <a
        href="https://www.youtube.com/watch?v=xeLBselSkrA&list=PLGSERCphonhgPnccXC1wPZd_ok8vKF82e"
        className="wwu-link"
        target="_blank"
      >
        Listen to more such stories
      </a>
    </div>
  );
};

export default cardsSec;
