import React, { useEffect } from 'react';
import "./styles.scss";
import { useInView } from "react-intersection-observer";

const GraphSec = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
    
  useEffect(() => {}, [inView]);

  return (
    <div className="wwu-edu-graph" ref={ref}>
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46n0nl5.png" loading="lazy" className="graphdotsLeft" />
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46n1udv.png" loading="lazy" className="graphdotsRight" />
    
      {inView ? <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46p4q00.gif" className="graph-img" loading="lazy" /> : <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46p61pn.png" oading="lazy" className="graph-img" />}
      <div className="edu-graph-text">
          We currently have the <span className="edu-graph-span">highest</span>{" "}
          placement rate across all online or offline education institutes.
          That’s because we've focused on a very narrow problem statement and
          gone<span className="edu-graph-nanum"> BIG</span> on that. We’ve
          designed everything around employability by focusing on
        <span className="edu-graph-span"> 4 pillars.</span>
      </div>
    </div>
  );
};

export default GraphSec;
