
export const section = [
  {
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46srvul.png",
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46rlcnu.png",
    title: `Application Screening`,
    desc: `After reviewing your profile, if we find you to be a suitable match for the role, we will get on a <b>brief exploratory call</b> with you to <b>understand more about your experience and share details of the selection process</b>.`,
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46twqsl.png",
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46rp7lj.png",
    title: `Skill Assessment interview`,
    desc: `This would be a conversation where <br/>we’ll <b>deep dive into your prior experience</b>, and you can expect to be evaluated on the competencies <br/>needed  for the role.`,
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46u1esp.png",
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46rt1et.png",
    title: `Technical Assignment`,
    desc: `For most (mid-senior or senior) roles,<br class="mobile-none"/> we will ask you to work on and submit <b><br class="mobile-none"/>a skill-based assignment that would be reflective of the work you’d be responsible for if you get shortlisted</b>. This is so you can get familiar with the work, and also for us to understand your competency level for the same.`,
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46u358d.png",
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46rp7lj.png",
    title: `Hiring Manager Interview`,
    desc: `This would be a chance for you to <b>meet your prospective manager / team lead</b>. It would be a mix of technical as well as a culture-fit conversation.`,
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46u4nrt.png",
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46rt1et.png",
    title: `Culture Fit Conversation`,
    desc: `This would be <b>a conversation with our Founder / Senior Leadership</b>  to ensure there’s a fit with the Kraftshala Kode`,
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46u6pwn.png",
    pin: "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46rp7lj.png",
    title: `Final Result`,
    desc: `If all goes well, we will extend an offer to you. If not, we’ll let you know that too. <b> You will hear back from us regardless of the outcome</b>.`,
  },
  
  
];
