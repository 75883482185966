import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import "./styles.scss";
import CardsSection from "./CardsSection";
import { useInView } from "react-intersection-observer";
import GraphSec from "./GraphSec";

const index = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {}, [inView]);

  return (
    <div className="wwu-education">
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46musxd.png" loading="lazy" className="edudotsLeft" />
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46myril.png" loading="lazy" className="edudotsRight" />

      <h2 className="wwu-edu-banner">
        Education has been our <br className="pc-hide" />
        most persistent challenge...
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46nkzkl.webp" className="DegIcon" loading="lazy" />
        <StaticImage
          className="wwu-edu-undeline"
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
        />
      </h2>
      <div className="wwu-edu-body">
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46oldoo.webp" loading="lazy" className="wwu-edu-img" />
        <div className="edu-content">
          <div className="edu-content-main">
          ...and it’s getting worse by the day. We <br className="pc-none" />remain to be a largely{" "}
            <span className="wwu-yellow-text">degree-hungry<br className="pc-none" /> nation</span> instead
          of an employable one,<br className="pc-none" /> where we have an increasing number of<br className="pc-none" /> graduates
          every year but practically{" "}
            <span className="wwu-yellow-text">
            no improvement in employability.
            </span>
          </div>
          <div className="bullet-contents">
            <div className="wwu-bullet">
              <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46omrrp.png" loading="lazy" className="bullet-img" />
              <div>
                Curriculums in colleges aren’t designed around{" "}
                <span className="wwu-yellow-text">industry-relevant </span> skill 
                <br className="mobile-none" />
                {" "}development
              </div>
            </div>

            <div className="wwu-bullet">
              <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46omrrp.png" loading="lazy" className="bullet-img" />
              <div>
                Teachers aren’t
                <span className="wwu-yellow-text">
                  {" "}
                  rightly incentivized
                </span>{" "}
                to care about how students are faring and
                <br className="mobile-none"/> what support they need to be successful <br className="mobile-none"/>
              </div>
            </div>
            <div className="wwu-bullet">
              <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46omrrp.png" loading="lazy" className="bullet-img" />
              <div>
                {" "}
                Well-laid-out{" "}
                <span className="wwu-yellow-text">tracking instruments</span> to
                give visibility on learning outcomes
                <br className="mobile-none"/> for students are almost non-existent
              </div>
            </div>
            <div className="wwu-bullet">
              <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46omrrp.png" loading="lazy" className="bullet-img" />
              <div>
                {" "}
                Access & exposure for students to able to form{" "}
                <span className="wwu-yellow-text">relevant goals</span> for
                <br className="mobile-none"/> themselves is lacking
              </div>
            </div>
          </div>
        </div>
      </div>
      <GraphSec />
      <CardsSection />
      <div className="wwu-core-val" ref={ref}>
        At the core - it's about figuring ways in which
        <span className="wwu-core-span">
          {" "}
          we can unlearn what we’ve learnt about learning
          {inView && <><svg
            width="180"
            height="4"
            viewBox="0 0 180 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="wwu-core-underline1"
          >
            <path
              d="M0.5 1.38948C11.7593 1.38948 23.0185 1.38948 34.2778 1.38948C51.4636 1.38948 68.5307 3.34955 85.7222 2.50059C116.957 0.95814 148.231 1.38948 179.5 1.38948"
              stroke="black"
              stroke-linecap="round"
            />
            <path
              d="M0.5 1.38948C11.7593 1.38948 23.0185 1.38948 34.2778 1.38948C51.4636 1.38948 68.5307 3.34955 85.7222 2.50059C116.957 0.95814 148.231 1.38948 179.5 1.38948"
              stroke="#505050"
              stroke-linecap="round"
            />
          </svg>
          <svg
            width="180"
            height="4"
            viewBox="0 0 180 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="wwu-core-underline2"
          >
            <path
              d="M0.5 1.38948C11.7593 1.38948 23.0185 1.38948 34.2778 1.38948C51.4636 1.38948 68.5307 3.34955 85.7222 2.50059C116.957 0.95814 148.231 1.38948 179.5 1.38948"
              stroke="black"
              stroke-linecap="round"
            />
            <path
              d="M0.5 1.38948C11.7593 1.38948 23.0185 1.38948 34.2778 1.38948C51.4636 1.38948 68.5307 3.34955 85.7222 2.50059C116.957 0.95814 148.231 1.38948 179.5 1.38948"
              stroke="#505050"
              stroke-linecap="round"
            />
          </svg> </>}
        </span>{" "}
        in the 14 years of formal education and really be able to find the joy
        in learning.
        {inView && (
          <svg
            width="554"
            height="6"
            viewBox="0 0 554 6"
            fill="none"
            className="wwu-edu-undeline-svg"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 4C13.736 4 25.9136 5 38.1389 5C55.0926 5 72.0463 5 89 5C111.702 5 134.441 5.36423 157.139 4.88889C187.306 4.25712 217.247 1 247.417 1C281.1 1 314.727 3.26184 348.444 2.38889C374.532 1.71348 400.568 2.68936 426.611 3.94444C437.52 4.47017 448.389 4.00401 459.25 2.94444C464.021 2.47901 468.489 1.5 473.333 1.5C485.56 1.5 497.724 2.3681 509.944 2.75C524.287 3.19821 538.651 3 553 3"
              stroke="#505050"
              stroke-width="1.5"
              stroke-linecap="round"
              className="draw"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default index;
