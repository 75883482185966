import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import "./styles.scss";

const JobListings = () => {
  useEffect(() => {
    // Load the CSS stylesheet
    const link = document.createElement("link");

    link.rel = "stylesheet";
    link.href =
      "https://static.zohocdn.com/recruit/embed_careers_site/css/v1.1/embed_jobs.css";
    link.type = "text/css";

    document.head.appendChild(link);

    // Load the JavaScript
    const script = document.createElement("script");

    script.src =
      "https://static.zohocdn.com/recruit/embed_careers_site/javascript/v1.1/embed_jobs.js";
    script.async = true;

    script.onload = () => {
      // Initialize the job listing widget once the script is loaded
      if (window.rec_embed_js) {
        window.rec_embed_js.load({
          widget_id: "rec_job_listing_div",
          page_name: "Careers",
          source: "CareerSite",
          site: "https://kraftshala.zohorecruit.in",
          brand_color: "#6875E2",
          empty_job_msg: "No current Openings",
        });
      }
    };

    document.body.appendChild(script);

    // Cleanup function to remove the stylesheet and script
    return () => {
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="wwu-openings" id="jobOpenings">
      <div className="wwu-sub-title">Kraftshala Careers</div>
      <h2 className="wwu-title-positions">  Open Positions </h2>
      <StaticImage
        className="wwu-select-undeline"
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
      />
      <div className="embed_jobs_head embed_jobs_with_style_3">
        <div className="embed_jobs_head2">
          <div className="embed_jobs_head3">
            <div id="rec_job_listing_div"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobListings;
